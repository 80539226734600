
import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'; // Ensures audio streaming is managed properly
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeLow } from '@fortawesome/free-solid-svg-icons'; // Updated monotone icon
import './Player.css';

const Player = () => {
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0.8); // Default volume level
    const [metadata, setMetadata] = useState({
        title: 'Stream', // Default title before metadata loads
        artist: 'Shaqfu Radio', // Default artist before metadata loads
        cover: 'https://radiocustoms-whitelabel-albumart.s3.us-east-2.amazonaws.com/Other%20Images/Shaq-Fu-Radio-Logo-White-80.png', // Default cover art
        duration: 0,
        currentTime: 0
    });

    const streamUrl = 'https://radiocustoms.cdnstream1.com/shaqfu'; // URL for streaming
    const metadataUrl = 'https://development.shaqfuradio.com/metadata'; // Endpoint for fetching metadata

    const togglePlay = () => {
        setPlaying(!playing); // Toggle play/pause state
    };

    useEffect(() => {
        const fetchMetadata = async () => {
            try {
                const { data } = await axios.get(metadataUrl);
            // Check if metadata contains "adwtag"
            if (data.title?.startsWith("adwtag")) {
                // Replace with custom message for ads
                setMetadata(metadata => ({
                    ...metadata,
                    title: "Thanks for listening!",
                    artist: "ShaqFu Radio",
                    cover: "https://radiocustoms-whitelabel-albumart.s3.us-east-2.amazonaws.com/Other%20Images/Shaq-Fu-Radio-Logo-White-80.png" // Optional: Default cover art for ads
                }));
            } else {
                // Update metadata normally
                setMetadata(metadata => ({ ...metadata, ...data })); // Update metadata state with fetched data
                }
            } catch (error) {
                console.error('Error fetching metadata:', error); // Log errors if fetch fails
            }
        };

        fetchMetadata();
        const interval = setInterval(fetchMetadata, 10000); // Fetch metadata every 10 seconds
        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    return (
        <div className="player-container">
            {/* Album Art Section */}
            <div className="album-art">
                <img src={metadata.cover} alt="Album Art" />
            </div>

            {/* Metadata Section */}
            <div className="metadata">
                <h1 className="title">{metadata.title}</h1>
                <h2 className="artist">{metadata.artist}</h2>
            </div>

            {/* React Player */}
            <ReactPlayer
                className="react-player"
                url={streamUrl}
                playing={playing}
                volume={volume}
                controls={false}
                width="0"
                height="0"
                config={{
                    file: {
                        attributes: {
                            preload: 'auto', // Preload audio for a smoother buffer
                            buffer: 3 // Set an approximate buffer size
                        }
                    }
                }}
            />

            {/* Controls Section */}
            <div className="controls">
                {/* Play/Pause Button */}
                <button
                    aria-label={playing ? 'Pause' : 'Play'}
                    onClick={togglePlay}
                    className={`play-button ${playing ? 'playing' : ''}`}
                >
                    <svg viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z" />
                        {playing ? (
                            <path d="M6 4h4v12h-4zM14 4h4v12h-4z" /> // Pause Icon
                        ) : (
                            <path d="M8 5v14l11-7z" /> // Play Icon
                        )}
                    </svg>
                </button>

                {/* Track Progress */}
                <div className="track-progress">
                    <span className="current-time">
                        {Math.floor(metadata.currentTime / 60)}:
                        {Math.floor(metadata.currentTime % 60)
                            .toString()
                            .padStart(2, '0')}
                    </span>
                    <input
                        type="range"
                        min="0"
                        max={metadata.duration}
                        value={metadata.currentTime}
                        onChange={(e) =>
                            setMetadata({
                                ...metadata,
                                currentTime: parseFloat(e.target.value)
                            })
                        }
                        className="progress-bar"
                    />
                    <span className="remaining-time">
                        {Math.floor((metadata.duration - metadata.currentTime) / 60)}:
                        {Math.floor((metadata.duration - metadata.currentTime) % 60)
                            .toString()
                            .padStart(2, '0')}
                    </span>
                </div>
            </div>

            {/* Volume Control Section */}
            <div className="volume-control">
                <FontAwesomeIcon icon={faVolumeLow} className="volume-icon" />
                <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={volume}
                    onChange={(e) => setVolume(parseFloat(e.target.value))}
                />
            </div>
        </div>
    );
};

export default Player;

